import React from "react";

function BlackEmailLogoSVG({ height = "16" }) {
    return (
        <svg
            height={height}
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.8889 0H2.66667C1.194 0 0 1.194 0 2.66667V13.3333C0 14.806 1.194 16 2.66667 16H16.8889C18.3616 16 19.5556 14.806 19.5556 13.3333V2.66667C19.5556 1.194 18.3616 0 16.8889 0ZM16.8889 1.77778C16.9916 1.77778 17.0902 1.79511 17.1818 1.82733L9.77778 7.75067L2.37378 1.82733C2.4679 1.79435 2.56694 1.7776 2.66667 1.77778H16.8889ZM16.8889 14.2222H2.66667C2.43092 14.2222 2.20483 14.1286 2.03813 13.9619C1.87143 13.7952 1.77778 13.5691 1.77778 13.3333V3.62711L9.22244 9.58289C9.38467 9.71289 9.58111 9.77778 9.77778 9.77778C9.97444 9.77778 10.1709 9.71289 10.3331 9.58289L17.7778 3.62711V13.3333C17.7778 13.5691 17.6841 13.7952 17.5174 13.9619C17.3507 14.1286 17.1246 14.2222 16.8889 14.2222Z"
                fill="black"
            />
        </svg>
    );
}

export default BlackEmailLogoSVG;
